export const TOKEN_PASS_AXIOS = '491E26F7-E1D7-4B53-9324-C28C80B41821';
export const ALPHA_WITH_ACCENTS = '^[a-zA-Z\u00C0-\u017F]+, [a-zA-Z\u00C0-\u017F]+$';
export const ALPHANUMERIC_PATTERN = '[a-zA-Z0-9]+';
export const ALPHANUMERIC_WITH_SPACE_PATTERN = '[a-zA-Z0-9 ]+';
export const ALPHANUMERIC_WITH_HYPHEN_PATTERN = '[a-zA-Z0-9-]+';
export const ALPHANUMERIC_WITH_HYPHEN_UNDERSCORE_PATTERN = '[_a-zA-Z0-9-]+';
export const ALPHANUMERIC_WITH_DOTS_COMMA_PATTERN = '[a-zA-Z0-9.,]+';
export const NUMERIC_PATTERN = '^[0-9]*$';
export const LAST_SAFE_SMALLDATE = '2079-06-05 23:59:59';
export const AGUARDE = 'Por favor, aguarde';
export const ESTACOD_DEFAULT = '1';
export const FAKE_COD = 666;
export const UI_VERSION = '11.29.0002+25976';
export const MAX_LENGTH_PASSWORD = 15;
export const MAX_LENGTH_CHAVE = 36;
export const URL_ARQUIVO_MODELO_PESSOA = '';
export const URL_ARQUIVO_MODELO_PRODUTO = 'https://popupseller.blob.core.windows.net/imagens-popup/template_carga_produtos.xlsx';
export const URL_ARQUIVO_MODELO_PRODUTO_CARGA_RAPIDA = 'https://popupseller.blob.core.windows.net/imagens-popup/template_carga_produtos_rapida_v2.xlsx';
export const NotificationLevel = {
  Success: 1,
  Error: 2,
  Warning: 3,
  Timeout: 4,
};
